<template>
  <div class="members edit_profile add_group" data-app>
    <div class="col-md-12 p-0 mx-auto">
      <div class="row page_header_all">
        <div class="col-lg-6 col-12 right_content">
          <div class="page_title">
            <div class="title_icon">
              <img src="/media/svg/plus.svg" />
            </div>
            <h3 class="title">حسابى</h3>
          </div>
        </div>
      </div>
      <div class="form_container meeting-link">
        <div class="form_title text-left">مرحبا بك</div>
        <div class="form_body">
          <div class="row">
            <div class="col-12 col-md-4 ">
              <div class="_info">
                <div class="personal_info">
                  <div class="header_img">
                    <div class="photo text-center">
                      <div
                        class="imagePreviewWrapper"
                        :style="{
                          'background-image': `url(${previewImage})`,
                        }"
                      >
                        <label
                          title="رفع صورة المستخدم"
                          class="image_uplode_input_label"
                        >
                          <i class="fas fa-upload"></i>
                          <input
                            class="image_uplode_input"
                            ref="fileInput"
                            type="file"
                            @input="pickFile"
                            size="60"
                          />
                        </label>
                      </div>
                    </div>
                    <h5 class="header_name">
                      {{ currentUserPersonalInfo.name }}
                    </h5>
                  </div>
                </div>
                <div class="actions">
                  <ul>
                    <li
                      :class="[edit_frame == 'data' && 'active']"
                      @click="edit_frame = 'data'"
                    >
                      <i class="icon fas fa-user"></i>
                      معلومات الحساب
                    </li>
                    <li
                      :class="[edit_frame == 'password' && 'active']"
                      @click="edit_frame = 'password'"
                    >
                      <i class="icon fas fa-lock"></i>
                      تغيير كلمة المرور
                    </li>
                    <!-- <li>
                        <i class="icon fas fa-qrcode"></i>
                        تسجيل دخول المصادقة الثانية
                      </li> -->
                    <li @click="onLogout">
                      <i class="icon fas fa-sign-out-alt"></i>
                      تسجيل خروج
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              class="metting_info col-12 col-md-8  justify-content-center d-flex"
            >
              <form class="row" v-if="edit_frame == 'data'">
                <div v-if="API_ERROR == 'email_taken'" class="error_mesage">
                  <p>
                    هذا البريد موجود بالفعل بالرجاء إختيار بريد إلكترونى اخر
                  </p>
                </div>

                <div v-if="API_ERROR == 'mobile_taken'" class="error_mesage">
                  <p>هذا الرقم موجود بالفعل بالرجاء إختيار رقم هاتف اخر</p>
                </div>
                <div v-if="API_ERROR == 'mobile_notvalid'" class="error_mesage">
                  <p>رقم الهاتف غير صالح</p>
                </div>
                <div v-if="API_ERROR == 'email_notvalid'" class="error_mesage">
                  <p>بريد إلكترونى غير صالح</p>
                </div>
                <div class="form-group col-md-12 d-block">
                  <label for="group_name">اسم الشخص</label>
                  <input
                    type="text"
                    v-model="currentUserPersonalInfo.name"
                    id="group_name"
                  />
                </div>
                <div class="form-group col-md-12 d-block">
                  <label for="">البريد الإلكتروني</label>
                  <input
                    type="email"
                    v-model="currentUserPersonalInfo.email"
                    id="group_name"
                  />
                </div>
                <div class="form-group col-md-12 d-block">
                  <label for="">رقم الموبايل</label>
                  <input
                    type="number"
                    v-model="currentUserPersonalInfo.mobile"
                    id="group_name"
                  />
                </div>
              </form>
              <form class="row" v-else>
                <div v-if="error_mesage.length" class="error_mesage">
                  <h3>برجاء ادخال البيانات التاليه :</h3>
                  <ul>
                    <li v-for="err in error_mesage" :key="err">
                      {{ err }}
                    </li>
                  </ul>
                </div>
                <div
                  v-if="API_ERROR == 'od_password_not_match'"
                  class="error_mesage"
                >
                  <p>
                    كلمة المرور القديمه غير صحيحه برجاء التأكد وإعاده المحاولة
                  </p>
                </div>
                <div v-if="API_ERROR == 'pass_same'" class="error_mesage">
                  <p>كلمة المرور وتأكيد كلمة المرور غير متشابهان</p>
                </div>

                <div class="form-group col-md-12 d-block">
                  <label for="group_name">كلمة المرور القديمة</label>
                  <input
                    type="password"
                    v-model="oldPassword"
                    id="group_name"
                  />
                </div>
                <div class="form-group col-md-12 d-block">
                  <label for="group_name">كلمة المرور الجديدة</label>
                  <input type="password" v-model="password" id="group_name" />
                </div>
                <div class="form-group col-md-12 d-block">
                  <label for="group_name">تأكيد كلمة المرور الجدده</label>
                  <input type="password" v-model="c_Password" id="group_name" />
                </div>
              </form>
            </div>
          </div>

          <div class="form-group col-md-12 col-12 form_footer">
            <button
              v-if="edit_frame == 'data'"
              type="button"
              @click="EditUser"
              class="   btn_Green"
            >
              <span>تعديل البيانات</span>
            </button>
            <button
              v-else
              type="button"
              @click="change_password"
              class="   btn_Green"
            >
              <span>تعديل كلمة المرور</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import users from "../../http/requests/users";
import { LOGOUT } from "@/core/services/store/auth.module";
export default {
  name: "edit-profile",
  data() {
    return {
      error_mesage: [],
      edit_frame: "",
      API_ERROR: "",
      previewImage: null,
      c_Password: null,
      password: null,
      oldPassword: null,
    };
  },
  methods: {
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => {
        sessionStorage.clear("lastUrl");
        window.location.href = "/";
      });
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      this.user_photo = input.files[0];
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewImage = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.$emit("input", file[0]);
      }
    },
    getUserData() {
      this.$store.dispatch(UPDATE_PERSONAL_INFO).then((res) => {});
    },
    EditUser(userid) {
      let formData = new FormData();
      formData.append("name", this.currentUserPersonalInfo.name);
      formData.append("email", this.currentUserPersonalInfo.email);
      formData.append("mobile", this.currentUserPersonalInfo.mobile);
      // formData.append("avatar", this.user_photo);
      formData.append("_method", "PUT");
      this.$vs.loading();
      users
        .UpdateProfile(formData)
        .then((res) => {
          if (this.previewImage) {
            this.currentUserPersonalInfo.avatar = this.previewImage;
          }
          this.getUserData();
          this.$vs.loading.close();
          this.$vs.notify({
            text: "تم تعديل البيانات بنجاح",
            color: "success",
          });
        })
        .catch((err) => {
          this.$vs.loading.close();

          if (err.response.data.error == "The email has already been taken.") {
            this.API_ERROR = "email_taken";
          } else if (
            err.response.data.error == "The mobile has already been taken."
          ) {
            this.API_ERROR = "mobile_taken";
          } else if (
            err.response.data.error == "The mobile must be a number."
          ) {
            this.API_ERROR = "mobile_notvalid";
          } else if (
            err.response.data.error ==
            "The email must be a valid email address."
          ) {
            this.API_ERROR = "email_notvalid";
          } else {
            if (err.response.data.error) {
              this.$vs.notify({
                text: err.response.data.error,
                color: "danger",
              });
            } else {
              this.$vs.notify({
                text: "حدث خطأ ما برجاء المحاوله فى وقت لاحق",
                color: "danger",
              });
            }
          }

          window.scrollTo(0, 0);
        });
    },
    change_password() {
      if (!this.password) {
        this.error_mesage.push("كلمة المرور الجديدة");
        this.isLoading = false;
      }
      if (!this.c_Password) {
        this.error_mesage.push("تأكيد كلمة المرور الجديدة");
        this.isLoading = false;
      }
      if (!this.oldPassword) {
        this.error_mesage.push("كلمة المرور القديمة");
        this.isLoading = false;
      }
      if (
        (this.password || this.c_Password) &&
        this.password != this.c_Password
      ) {
        this.API_ERROR = "pass_same";
        this.isLoading = false;
      }
      if (
        this.password &&
        this.c_Password &&
        this.oldPassword &&
        this.password == this.c_Password
      ) {
        this.$vs.loading();
        const passwordForm = {
          current_password: this.oldPassword,
          new_password: this.password,
          new_confirm_password: this.c_Password,
        };
        users
          .change_password(passwordForm)
          .then((res) => {
            this.$vs.loading.close();
            this.$vs.notify({
              text: "تم تعديل كلمة المرور بنجاح",
              color: "success",
            });
            this.onLogout();
            window.localStorage.setItem("paswordChanged", true);
          })
          .catch((err) => {
            this.$vs.loading.close();

            if (
              err.response.data.error ==
              "The current password is match with old password."
            ) {
              this.API_ERROR = "od_password_not_match";
            }
          });
      }
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
  },
  created() {
    // this.userData = JSON.parse(localStorage.getItem("User_data"));
    this.previewImage = this.currentUserPersonalInfo.avatar;
    this.edit_frame = "data";
  },
};
</script>
<style scoped lang="scss">
.photo {
  margin-bottom: 0 !important;
  padding-top: 25px;
}
.form_container {
  width: 100%;
}
form {
  height: fit-content;
}
.form-group {
  height: fit-content;
}
.form_footer {
  text-align: end;
  margin-top: 20px;
  margin-bottom: 0;
  .btn_Green {
    padding: 7px 20px;
  }
}
</style>
